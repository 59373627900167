<template>
  <Popup ref="popupRef" width="610px" @close="resetForm" :showCancel="false" :showConfirm="false">
    <template #title>
      <PanelTitle title="回复详情" :titleStyle="{'font-size': '16px', color: '#001A18'}" style="height: auto; padding-left: 0;"/>
    </template>
    <div class="flex" v-if="detailData">
      <LoadImage class="avatar" :src="detailData.toAvatar"></LoadImage>
      <div class="flex-1">
        <div>
          <div class="name">{{detailData.toNick}}</div>
          <div class="time mt6">发布时间：{{detailData.beGmtCreate || '-'}}</div>
        </div>
        <div class="content mt16">{{detailData.content}}</div>
        <div>
          <div class="flex mt16">
            <LoadImage class="avatar" :src="detailData.fromAvatar"></LoadImage>
            <div class="flex flex-1 between-center">
              <div>
                <div class="name">{{detailData.formNick}}</div>
                <div class="time mt6">发布时间：{{detailData.gmtCreate || '-'}}</div>
              </div>
            </div>
          </div>
          <div class="content mt8">{{detailData.replyContent}}</div>
        </div>
      </div>
    </div>
    
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import { queryReplyDetailsApi } from '@/api/admin.js'
import { dateFormatUtil } from '@/utils/util.js'
export default {
  components: {
    Popup,
    PanelTitle,
    LoadImage
  },
  data() {
    return {
      detailData: null,
      replyId: ''
    }
  },
  methods: {
    open(option = {}) {
      this.replyId = option.detail?.replyId || ''
      this.getReplyDetail()
    },
    resetForm() {
      this.replyId = ''
      this.detailData = null
    },
    getReplyDetail() {
      queryReplyDetailsApi({replyId: this.replyId}).then(res => {
        console.log('回复详情',res)
        if(res.data?.gmtCreate) {
          res.data.gmtCreate = dateFormatUtil(res.data.gmtCreate, '-', true)
        }
        if(res.data?.beGmtCreate) {
          res.data.beGmtCreate = dateFormatUtil(res.data.beGmtCreate, '-', true)
        }
        this.detailData = res.data
        this.$refs.popupRef.open()
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 20px;
  overflow: hidden;
}
.name {
  font-size: 14px;
  font-weight: bold;
}
.time {
  font-size: 10px;
}
.content {
  font-size: 14px;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  .img {
    width: 68px;
    height: 68px;
    margin: 8px 8px 0 0;
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>